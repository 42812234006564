import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";

// Image
import GameImage from "../../Assets/Images/Gameplay/MobileProp.png";

// Shared Component
import OrangeButton from "../../components/OrangeButton.js";
import SecondaryButton from "../../components/SecondaryButton.js";
import GradientText from "../../components/GradientText.js";

// styled component styles
const Container = styled.section`
	padding: 10rem 0 6rem;

	@media (max-width: 600px) {
		display: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 8rem 0 4rem 0;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const ImageContainer = styled.div``;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
	aspect-ratio: 16/12;
`;

const TextContainer = styled.div`
	@media (max-width: 600px) {
		text-align: center;
		margin-bottom: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const Header = styled.h2`
	color: var(--DarkGreen);
	font: 2.5rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 4.5rem;
	}
`;

const DescriptionWrapper = styled.div`
	margin-top: 2rem;
	width: 90%;

	@media (max-width: 600px) {
		width: 85%;
		margin: 2rem auto 0 auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 1rem;
	}
`;

const Description = styled.p`
	color: var(--GreyDescriptionColor);
	font: 1rem Montserrat;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 4rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 2rem;
	}
`;

export default function AboutUs() {
	return (
		<Container id='aboutUsSection'>
			<Wrapper>
				<Grid container justifyContent='space-around' alignItems='center' direction='row-reverse'>
					{/* Text Grid */}
					<Grid item xs={12} sm={6} md={5}>
						<TextContainer>
							<Header>
								The <GradientText>Ultimate</GradientText> Royale Game for All Ages
							</Header>
							<DescriptionWrapper>
								<Description>
									Farm Royale is an addictive, easy to learn, challenging to master game that puts
									your strategic skills to test.
								</Description>
							</DescriptionWrapper>
							<ButtonWrapper>
								{/* Edit the href (link) if you want to update the link */}
								<OrangeButton href='/'>Play Now</OrangeButton>
								<SecondaryButton href='/'>Explore More</SecondaryButton>
							</ButtonWrapper>
						</TextContainer>
					</Grid>
					{/* Image Grid */}
					<Grid item xs={12} sm={6} md={5}>
						<ImageContainer>
							<Image src={GameImage} alt='Farm Royale Hero Image' loading='lazy' />
						</ImageContainer>
					</Grid>
				</Grid>
			</Wrapper>
		</Container>
	);
}
