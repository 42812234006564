import React from "react";
import styled from "styled-components";

// Image
import RightWave from "../../Assets/Images/GridRightWave.png";

// Shared Component
import GradientText from "../../components/GradientText.js";

// styled component
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 8rem 0 10rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0 6rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0;
	}
`;

// const BottomImage = styled.img`
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	z-index: -1;
// 	width: 100%;
// 	object-fit: contain;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;

// 	@media (max-width: 600px) {
// 		width: 200%;
// 	}
// `;

const RightSideWave = styled.img`
	position: absolute;
	top: -20%;
	right: 0;
	z-index: -1;
	height: 110%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const HeaderWrapper = styled.div`
	text-align: center;
	margin-bottom: 4rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
	@media (min-width: 1900px) {
		margin-bottom: 6rem;
	}
`;

const Header = styled.h2`
	color: var(--DarkGreen);
	font: 2.2rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.6rem;
	}
	@media (min-width: 1900px) {
		font-size: 5rem;
	}
`;

const DescriptionWrapper = styled.div`
	width: 70%;
	margin: auto;
	text-align: center;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 80%;
	}
`;

const Description = styled.p`
	color: var(--GreyDescriptionColor);
	font: 1rem Montserrat;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (max-width: 600px) {
		/* font-size: 1rem; */
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

export default function WelcomeMessage() {
	return (
		<Container>
			<RightSideWave src={RightWave} alt='Bottom Wave Vector' />
			<Wrapper>
				<HeaderWrapper>
					<Header>
						Welcome to <GradientText>Farm Royale</GradientText>
					</Header>
				</HeaderWrapper>
				<DescriptionWrapper>
					<Description>
						Welcome to Farm Royale – the unique and exciting new game that features unique random plants,
						each with their own unique features, looks, and stats used for defending your gardens.
						<br />
						<br />
						This addictive strategy-based gardening game has a massive level to keep you entertained for
						hours! Your objective is to protect your gardens at all costs from the marauding mice, while
						also building paths to connect your industries to maximize profit.
						<br />
						<br />
						Collect seeds, ore, gold, and wood used to unlock new random plants, and paths to connect more
						industries together!
						<br />
						<br />
						With awesome features like path building and the ever-threatening Mouse, Farm Royale is a
						must-play for anyone looking for an edge in their next gaming session
					</Description>
				</DescriptionWrapper>
			</Wrapper>
		</Container>
	);
}
