import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, SwipeableDrawer, List, ListItem, IconButton, Divider } from "@mui/material";
import styled from "styled-components";

// image
import LogoImage from "../Assets/Images/Logo/BattleFarmLogo.png";

// shared component
import OrangeButton from "./OrangeButton.js";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// styled components
const Wrapper = styled.div`
	display: flex;
	align-items: center;
	width: 88%;
	margin: 1rem auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const LogoWrapper = styled.div`
	flex-grow: 1;
`;

const LogoBox = styled.div`
	display: flex;
	align-items: center;
`;

const LogoLink = styled.a`
	text-decoration: none;
`;

const Image = styled.img`
	width: 3.5rem;
	height: 100%;
	object-fit: cover;
	margin-right: 0.5rem;

	@media (max-width: 600px) {
		width: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 2.2rem;
	}
`;

const Logo = styled.p`
	color: var(--DarkGreen);
	font: 1.3rem Montserrat;
	font-weight: 700;
	letter-spacing: 1px;
	text-decoration: none;

	@media (max-width: 600px) {
		font-size: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.9rem;
	}
	@media (min-width: 1900px) {
		font-size: 2rem;
	}
`;

const DesktopWrapper = styled.div`
	margin-right: 2rem;

	@media (max-width: 1024px) {
		display: none;
	}
`;

const TabLink = styled.a`
	text-decoration: none;
	color: var(--buttonWhiteText);
`;

const DesktopButton = styled.button`
	font: 0.9rem Montserrat;
	font-weight: 400;
	background: transparent;
	cursor: pointer;
	border: none;
	letter-spacing: 0.5px;
	border-radius: 0.3rem;
	margin: 0 0.5rem;
	padding: 0.7rem 1rem;

	&:hover {
		color: var(--LightTextColor);
		background-color: var(--DarkGreen);
		transition: all 0.3s ease-in-out;
	}
	@media (min-width: 1900px) {
		font-size: 1.3rem;
	}
`;

const CtaWrapper = styled.div`
	@media (max-width: 1024px) {
		display: none;
	}
`;

const MobileWrapper = styled.div`
	display: none;

	@media (max-width: 1024px) {
		display: block;
	}
`;

const MobileLink = styled.a`
	text-decoration: none;
	color: var(--DarkGreen);
	width: 100%;
`;

const MobileButton = styled.button`
	color: var(--DarkGreen);
	font: 0.9rem Montserrat;
	font-weight: 500;
	background: transparent;
	display: flex;
	justify-content: flex-start;
	border: none;
	letter-spacing: 0.5px;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	cursor: pointer;
`;

export default function NavBar() {
	const [navColor, setNavColor] = useState("transparent");
	const [drawer, setDrawer] = useState(false);

	const drawerHandler = () => {
		setDrawer(!drawer);
	};

	const navColorHandler = () => {
		if (window.scrollY < 100) {
			setNavColor("transparent");
		} else if (window.scrollY > 70) {
			setNavColor("#fcfcfc");
		} else {
			setNavColor("#fcfcfc");
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", navColorHandler);

		return () => {
			window.removeEventListener("scroll", navColorHandler);
		};
	}, []);

	return (
		<AppBar
			position='fixed'
			elevation={0}
			color='transparent'
			sx={{
				height: 80,
				background: navColor,
			}}
		>
			<Toolbar>
				<Wrapper>
					<LogoWrapper>
						<LogoLink href='/'>
							<LogoBox>
								<Image src={LogoImage} alt='Battle Farm Logo' loading='lazy' />
								<Logo>Farm Royale</Logo>
							</LogoBox>
						</LogoLink>
					</LogoWrapper>

					<DesktopWrapper>
						<TabLink href='/'>
							<DesktopButton>Home</DesktopButton>
						</TabLink>
						<TabLink href='#aboutUsSection'>
							<DesktopButton>About Game</DesktopButton>
						</TabLink>
						<TabLink href='#downloadSection'>
							<DesktopButton>Download</DesktopButton>
						</TabLink>
					</DesktopWrapper>

					<CtaWrapper>
						<OrangeButton href={"#downloadSection"}>Play Now</OrangeButton>
					</CtaWrapper>

					<MobileWrapper onClick={drawerHandler} style={{ cursor: "pointer" }}>
						<MenuIcon sx={{ fontSize: "2rem", color: "#050505" }} />
					</MobileWrapper>
				</Wrapper>

				<SwipeableDrawer anchor='right' open={drawer} onOpen={drawerHandler} onClose={drawerHandler}>
					<List sx={{ width: 280 }}>
						<ListItem sx={{ justifyContent: "flex-end" }}>
							<IconButton onClick={drawerHandler}>
								<CloseIcon sx={{ color: "var(--DarkGreen)", fontSize: "2rem" }} />
							</IconButton>
						</ListItem>
						<Divider />
						<ListItem>
							<MobileLink href='/' onClick={drawerHandler}>
								<MobileButton>Home</MobileButton>
							</MobileLink>
						</ListItem>
						<Divider />
						<ListItem>
							<MobileLink href='#aboutUsSection' onClick={drawerHandler}>
								<MobileButton>About Game</MobileButton>
							</MobileLink>
						</ListItem>
						<Divider />
						<ListItem>
							<MobileLink href='#downloadSection' onClick={drawerHandler}>
								<MobileButton>Download</MobileButton>
							</MobileLink>
						</ListItem>
						<Divider />
						<Divider />
					</List>
				</SwipeableDrawer>
			</Toolbar>
		</AppBar>
	);
}
