import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// image
import LogoImage from "../Assets/Images/Logo/BattleFarmLogo.png";

// icons
import Email from "../Assets/Images/socialMediaIcon/email.png";
import Facebook from "../Assets/Images/socialMediaIcon/facebook.png";
import Instagram from "../Assets/Images/socialMediaIcon/instagram.png";
import Twitter from "../Assets/Images/socialMediaIcon/twitter.png";

// styled components styles
const Container = styled.footer`
	background: var(--DarkGreen);
	padding: 4rem 0 2rem 0;
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;
	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const LogoBox = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 600px) {
		justify-content: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		align-items: flex-start;
	}
`;

const Image = styled.img`
	width: 4rem;
	height: 100%;
	object-fit: cover;
	margin-right: 0.5rem;

	@media (max-width: 600px) {
		width: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 2rem;
	}
	@media (min-width: 1900px) {
		width: 6rem;
	}
`;

const Logo = styled.h2`
	color: var(--LightTextColor);
	font: 1.5rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-decoration: none;

	@media (max-width: 600px) {
		font-size: 1.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
	}
	@media (min-width: 1900px) {
		font-size: 2rem;
	}
`;

const Description = styled.p`
	font: 0.9rem Montserrat;
	color: var(--LightTextColor);

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

const GridLabel = styled.h6`
	font: 1.1rem Montserrat;
	font-weight: 600;
	color: var(--LightTextColor);
	letter-spacing: 0.5px;
	margin-bottom: 1.5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
		margin-bottom: 3rem;
	}
`;

const LinkBox = styled.div`
	margin-bottom: 1.6rem;

	@media (min-width: 1900px) {
		margin-bottom: 2rem;
	}
`;

const LinkButton = styled.a`
	font-size: 1rem;
	color: var(--LightTextColor);
	border-radius: 0.3rem;
	padding: 0.4rem 1rem;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background: var(--LightTextColor);
		color: var(--DarkGreen);
		transition: all 0.2s linear;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.4rem;
	}
`;

const Icon = styled.img`
	width: 2rem;
	margin-right: 0.5rem;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
		transition: all 0.35s ease-in-out;
	}
	@media (max-width: 600px) {
		width: 2.5rem;
		margin: 1rem 1rem 0 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 1.2rem;
	}
	@media (min-width: 1900px) {
		width: 3.5rem;
		margin: 0 1.5rem 0 0;
	}
`;

const RightContainer = styled.div`
	padding: 4rem 0 0 0;
	text-align: center;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding-top: 2rem;
	}
`;

const RightText = styled.p`
	font: 0.9rem Montserrat;
	color: var(--LightTextColor);
	letter-spacing: 0.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.6rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.5rem;
	}
`;

export default function Footer() {
	const [year, setYear] = useState("");

	// Website Route link title
	const dataRoute = [
		{ label: "Home", link: "#homeSection" },
		{ label: "About Us", link: "#aboutUsSection" },
		{ label: "Download", link: "#downloadSection" },
		{ label: "Trading", link: "#tradingSection" },
	];

	// Website About link title
	const dataComponent = [
		{ label: "Products", link: "" },
		{ label: "Privacy Policy", link: "" },
		{ label: "Terms and Conditions", link: "" },
		{ label: "Support", link: "mailto:support@zanoski.com" },
	];

	// Retrieve Year function
	const YearHandler = () => {
		const yearValue = new Date().getFullYear();
		setYear(yearValue);
	};

	useEffect(() => {
		YearHandler();
	}, []);

	return (
		<Container>
			<Wrapper>
				<Grid container justifyContent='space-around'>
					<Grid
						item
						xs={12}
						sm={3}
						md={3}
						sx={{
							marginTop: { xs: "2rem", sm: "0", md: "0" },
							padding: { xs: "0 2rem", sm: "0", md: "0" },
							textAlign: { xs: "center", sm: "left", md: "left" },
						}}
					>
						<LogoBox>
							<Image src={LogoImage} alt='Battle Farm Logo' loading='lazy' />
							<Logo>Farm Royale</Logo>
						</LogoBox>
						{/* Add Any Description You Want in the DESCRIPTION <> */}
						<Description></Description>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						md={2}
						sx={{
							marginTop: { xs: "2rem", sm: "0", md: "0" },
							padding: { xs: "0 1rem", sm: "0", md: "0" },
						}}
					>
						<GridLabel>Explore More</GridLabel>
						{dataRoute.map((item, index) => (
							<LinkBox key={index}>
								<LinkButton href={item.link}>{item.label}</LinkButton>
							</LinkBox>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						md={2}
						sx={{
							marginTop: { xs: "2rem", sm: "0", md: "0" },
							padding: { xs: "0 1rem", sm: "0", md: "0" },
						}}
					>
						<GridLabel>Our Company</GridLabel>
						{dataComponent.map((item, index) => (
							<LinkBox key={index}>
								<LinkButton href={item.link}>{item.label}</LinkButton>
							</LinkBox>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						md={3}
						sx={{
							marginTop: { xs: "2rem", sm: "0", md: "0" },
							padding: { xs: "0 1rem", sm: "0", md: "0" },
							textAlign: { xs: "center", sm: "left", md: "left" },
						}}
					>
						{/* Set the Link for the Social Media in the a[tag] after the href  */}
						<GridLabel>Social Media</GridLabel>
						<a href='mailto:mailto:support@zanoski.com'>
							<Icon src={Email} alt='Farm Royale Email Icon' loading='lazy' />
						</a>
						<a href='/'>
							<Icon src={Facebook} alt='Farm Royale Facebook Icon' loading='lazy' />
						</a>
						<a href='/'>
							<Icon src={Twitter} alt='Farm Royale Twitter Icon' loading='lazy' />
						</a>
						<a href='/'>
							<Icon src={Instagram} alt='Farm Royale Instagram Icon' loading='lazy' />
						</a>
					</Grid>
				</Grid>
				<RightContainer>
					<RightText>&copy;All Rights Reserved by Farm Royale {year}.</RightText>
				</RightContainer>
			</Wrapper>
		</Container>
	);
}
