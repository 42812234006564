import React from "react";
import styled from "styled-components";

// Icon images

import LevelUp from "../../Assets/Images/Gameplay/PhoneBanner.png";
import PathWay from "../../Assets/Images/Gameplay/PhonePath.png";
import TowerDefend from "../../Assets/Images/Gameplay/PhoneCastle.png";
import PlantTrade from "../../Assets/Images/Gameplay/PhoneSword.png";

// React Slick Css library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// react slick custom Css
import "../../Assets/Css/reactSlider.css";

// styled component
const Container = styled.section`
	background-color: var(--SecondaryBackground);
	padding: 8rem 0 10rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0 8rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0 6rem 0;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const CarouselContainer = styled.div``;

const CarouselBox = styled.div`
	position: relative;
	background-color: #ffa500;
	background-image: linear-gradient(315deg, #ffa500 0%, #fd1c03 74%);
	box-shadow: 1px 4px 15px #708090;
	width: 50%;
	margin: auto;
	padding: 2rem;
	border-radius: 1rem;
	text-align: center;
	z-index: 0;

	@media (max-width: 600px) {
		width: 95%;
		padding: 2rem 0;
		box-shadow: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 50%;
	}
	@media (min-width: 1900px) {
		border-radius: 4rem;
		padding: 4rem 2rem;
	}
`;

const Title = styled.h3`
	font: 1.5rem Montserrat;
	font-weight: 600;
	color: #fcfcfc;
	letter-spacing: 0.5px;
	margin-top: 2rem;
	line-height: 170%;

	@media (max-width: 600px) {
		margin-top: 2rem;
		padding: 0 2rem;
		font-size: 1.2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1rem;
	}
	@media (min-width: 1900px) {
		font-size: 3rem;
		padding: 0 2rem;
	}
`;

const ImageBox = styled.div`
	position: relative;
	/* top: -10px; */
	width: 40%;
	margin: auto;
	text-align: center;
	border-radius: 1rem;
`;

const Image = styled.img`
	margin: auto;
	width: 12rem;

	@media (max-width: 600px) {
		width: 8rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 6rem;
	}
	@media (min-width: 1900px) {
		width: 20rem;
	}
`;

export default function ImageCarousel() {
	// Slider specification
	var sliderSettings = {
		dots: true,
		arrows: false,
		infinite: true,
		autoplay: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		cssEase: "linear",
	};

	// Slider Images
	const carouselData = [
		{
			name: "1. Challenging levels, harder each time",
			imageSource: LevelUp,
		},
		{ name: "2. Plant Trading", imageSource: PlantTrade },
		{ name: "3. Tower Defense Mode", imageSource: TowerDefend },
		{ name: "4. Path Connections Mode", imageSource: PathWay },
	];

	return (
		<Container>
			<Wrapper>
				<CarouselContainer>
					<Slider {...sliderSettings}>
						{carouselData.map((item, id) => (
							<div key={id}>
								<CarouselBox mainColor={item.mainColor} secondColor={item.secondColor}>
									<ImageBox>
										<Image src={item.imageSource} alt={item.name} loading='lazy' />
									</ImageBox>
									<Title>{item.name}</Title>
								</CarouselBox>
							</div>
						))}
					</Slider>
				</CarouselContainer>
			</Wrapper>
		</Container>
	);
}
