import React from "react";
import styled from "styled-components";

// Shared Component
import GradientText from "../../components/GradientText.js";

// Images
import HumanImage from "../../Assets/Images/MoreImageHuman.png";
import HenImage from "../../Assets/Images/MoreImageHen.png";

// styled component
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 6rem 0 8rem 0;
	background-color: var(--SecondaryBackground);

	@media (max-width: 600px) {
		padding: 2rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0 6rem 0;
	}
	@media (min-width: 1900px) {
		padding: 10rem 0;
	}
`;

const LeftSideHen = styled.img`
	position: absolute;
	bottom: 15%;
	right: 0;
	z-index: -1;
	height: 55%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		height: 30%;
		bottom: 20%;
	}
`;

const RightSideHuman = styled.img`
	position: absolute;
	bottom: 2%;
	left: 0;
	z-index: -1;
	height: 55%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		height: 30%;
		bottom: 5%;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const HeaderWrapper = styled.div`
	text-align: center;
	margin-bottom: 3rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const Header = styled.h2`
	color: var(--DarkGreen);
	font: 2.2rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.6rem;
	}
	@media (min-width: 1900px) {
		font-size: 4rem;
	}
`;
const DescriptionWrapper = styled.div`
	width: 70%;
	margin: auto;
	text-align: center;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 80%;
	}
`;

const Description = styled.p`
	color: var(--GreyDescriptionColor);
	font: 1rem Montserrat;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

export default function TradeDetail() {
	return (
		<Container id='tradingSection'>
			<LeftSideHen src={HenImage} alt='Human Image Vector' />
			<RightSideHuman src={HumanImage} alt='Human Image Vector' />
			<Wrapper>
				<HeaderWrapper>
					<Header>
						What's <GradientText>More?</GradientText>
					</Header>
				</HeaderWrapper>
				<DescriptionWrapper>
					<Description>
						Got a 3-star green sunflower in your card pack that you don't need? Let's sell it to other
						players, and buy yourself a golden sunflower 5 star. After all, the golden sunflower has 2 corn
						machine guns. You can trade cards with other players and buy new ones.
						<br />
						<br />
						Farm Royale is a fun and addicting card based tower defense game. You've never seen anything
						like Farm Royale before!
						<br />
						<br />
						The more industries you connect to, the more you earn while you sleep!
						<br />
						<br />
						Endless engagement and fun, right? A game that will keep you on your toes for hours. It's fast
						paced and easy to learn but difficult to master.
						<br />
						<br />
					</Description>
				</DescriptionWrapper>
			</Wrapper>
		</Container>
	);
}
