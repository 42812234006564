import React from "react";
import styled from "styled-components";

const Link = styled.a``;

const Button = styled.button`
	font: 0.9rem Montserrat;
	font-weight: 600;
	background-color: #ffa500;
	background-image: linear-gradient(315deg, #ffa500 0%, #fd1c03 74%);
	color: #fcfcfc;
	border-radius: 1rem 0 1rem 0;
	cursor: pointer;
	border: none;
	padding: 0.8rem 1.5rem;
	letter-spacing: 0.5px;

	&:hover {
		background-color: var(--ButtonHoverColor);
		transition: all 0.2s linear;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.65rem;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem 0 0.5rem 0;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
		padding: 1.2rem 2.5rem;
	}
`;

export default function OrangeButton({ href, children }) {
	return (
		<Link href={href}>
			<Button>{children}</Button>
		</Link>
	);
}
