import React from "react";
import styled from "styled-components";

// styled component
const Container = styled.section`
	padding: 6rem 0;

	@media (max-width: 600px) {
		/* padding: 8rem 0 1rem 0; */
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 80%;
	}
`;

const GreenBox = styled.div`
	position: relative;
	background-color: #00b712;
	background-image: linear-gradient(315deg, #149b11 0%, #14db10 74%);

	border-radius: 5rem 0 5rem 0;
	padding: 5rem 4rem;

	&::before {
		position: absolute;
		/* change to white to check */
		background-color: #ef5734;
		background-image: linear-gradient(315deg, #ef5734 0%, #ffcc2f 74%);
		content: "";
		inset: 0;
		margin: -0.5rem;
		border-radius: 5.5rem 0 5.5rem 0;
		z-index: -1;
	}

	@media (max-width: 600px) {
		padding: 3rem 2rem;
		border-radius: 3rem 0 3rem 0;

		&::before {
			border-radius: 3.5rem 0 3.5rem 0;
		}
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem;
		border-radius: 3rem 0 3rem 0;

		&::before {
			border-radius: 3.5rem 0 3.5rem 0;
		}
	}
	@media (min-width: 1900px) {
		padding: 7rem;

		&::before {
			margin: -1rem;
		}
	}
`;

const Text = styled.h3`
	color: var(--LightTextColor);
	font: 1.8rem Montserrat;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (max-width: 600px) {
		font-size: 1.2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.2rem;
	}
	@media (min-width: 1900px) {
		font-size: 3.2rem;
	}
`;

export default function Banner() {
	return (
		<Container>
			<Wrapper>
				<GreenBox>
					<Text>
						So don't delay – download Farm Royale today!
						<br />
						Farm, grow, and battle your way to the victory
					</Text>
				</GreenBox>
			</Wrapper>
		</Container>
	);
}
