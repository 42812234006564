import React from "react";
import styled from "styled-components";

// Shared Component
import GradientText from "../../components/GradientText.js";

// image
import BottomWaveImage from "../../Assets/Images/DownloadBottomWave.png";
import GooglePlayLogo from "../../Assets/Images/Logo/googlePlay.png";
// import AppStoreLogo from "../../Assets/Images/Logo/appStore.png";

// styled component
const Container = styled.section`
	position: relative;
	overflow: hidden;
	z-index: 0;
	padding: 8rem 0 10rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0;
	}
	@media (min-width: 1900px) {
		padding: 10rem 0;
	}
`;

const BottomWave = styled.img`
	position: absolute;
	bottom: -5%;
	right: 0;
	z-index: -1;
	height: 100%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	opacity: 0.7;
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const HeaderWrapper = styled.div`
	text-align: center;
	margin-bottom: 3rem;

	@media (max-width: 600px) {
		/* padding: 8rem 0 1rem 0; */
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		/* padding: 8rem 0 4rem 0; */
	}
	@media (min-width: 1900px) {
		margin-bottom: 5rem;
	}
`;

const Header = styled.h2`
	color: var(--DarkGreen);
	font: 2.2rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.6rem;
	}
	@media (min-width: 1900px) {
		font-size: 4rem;
	}
`;

const DescriptionWrapper = styled.div`
	width: 70%;
	margin: auto;
	text-align: center;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 80%;
	}
`;

const Description = styled.p`
	color: var(--GreyDescriptionColor);
	font: 1rem Montserrat;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (max-width: 600px) {
		/* font-size: 1rem; */
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: 6rem;
`;

const ButtonLink = styled.a``;

const Button = styled.img`
	position: relative;
	height: 100%;
	width: 14rem;
	object-fit: cover;
	margin: 0 1rem;

	&:hover {
		transform: scale(1.07);
		transition: all 0.25s linear;
	}
	@media (max-width: 600px) {
		width: 16rem;
		margin-bottom: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 8rem;
	}
	@media (min-width: 1900px) {
		width: 22rem;
	}
`;

export default function DonwloadMessage() {
	return (
		<Container id='downloadSection'>
			<BottomWave src={BottomWaveImage} alt='Bottom Wave Vector' />
			<Wrapper>
				<HeaderWrapper>
					<Header>
						<GradientText>Download</GradientText> Farm Royale Now!
					</Header>
				</HeaderWrapper>
				<DescriptionWrapper>
					<Description>
						Get Farm Royale today for FREE on the Google Play store. Be sure to follow us on Pinterest,
						Facebook, Twitter & Instagram @FarmRoyaleGame where you can get updates about new features being
						added!
						<br />
						<br />
						<GradientText>Happy Farming!</GradientText>
					</Description>
				</DescriptionWrapper>
				<ButtonWrapper>
					{/* Add the link to the Google play and App Store after the href */}
					<ButtonLink href='/' target='_blank' rel='nofollow noopener'>
						<Button src={GooglePlayLogo} alt='Farm Royale Google Play Button' />
					</ButtonLink>
					{/* This Button is for the App Store, Uncomment below code to display the App Store Button */}
					{/* <ButtonLink href='/' target='_blank' rel='nofollow noopener'>
						<Button src={AppStoreLogo} alt='Farm Royale App Store Button' />
					</ButtonLink> */}
				</ButtonWrapper>
			</Wrapper>
		</Container>
	);
}
