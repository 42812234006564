import React from "react";

// components
import HeroSection from "./Hero.js";
import AboutUsSection from "./AboutUs.js";
import WelcomeMessageSection from "./WelcomeMessage.js";
import ImageGrid from "./ImageGrid.js";
import Banner from "./Banner.js";
import CarouselSection from "./ImageCarousel.js";
import TradeDetailSection from "./TradeDetail.js";
import DownloadMessageSection from "./DonwloadMessage.js";

export default function Index() {
	return (
		<div>
			<HeroSection />
			<AboutUsSection />
			<WelcomeMessageSection />
			<ImageGrid />
			<Banner />
			<CarouselSection />
			<TradeDetailSection />
			<DownloadMessageSection />
		</div>
	);
}
