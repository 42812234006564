import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";

// Shared Component
import GradientText from "../../components/GradientText.js";

// images
import LeftWave from "../../Assets/Images/GridLeftWave.png";
import RightWave from "../../Assets/Images/GridRightWave.png";
import GameImage from "../../Assets/Images/Gameplay/MobileProp.png";

// styled component
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 4rem 0;

	@media (max-width: 600px) {
		padding: 2rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 2rem 0;
	}
`;

const LeftSideWave = styled.img`
	position: absolute;
	top: -10%;
	left: -2%;
	z-index: -1;
	height: 100%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
`;

const RightSideWave = styled.img`
	position: absolute;
	top: 10%;
	right: -2%;
	z-index: -1;
	height: 100%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 85%;
	}
`;

const GridContainer = styled.div``;

const GridBox = styled.div`
	margin: 2rem 0;
	padding: 2rem;

	@media (max-width: 600px) {
		padding: 1rem;
	}
`;

const TextWrapper = styled.div``;

const Header = styled.h3`
	color: var(--DarkGreen);
	font: 1.7rem Montserrat;
	font-weight: 600;
	letter-spacing: 0.5px;
	line-height: 160%;

	@media (max-width: 600px) {
		font-size: 1.4rem;
		text-align: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.1rem;
	}
	@media (min-width: 1900px) {
		font-size: 3.5rem;
	}
`;

const ImageWrapper = styled.div`
	text-align: center;

	@media (max-width: 600px) {
		margin-top: 2rem;
	}
`;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
	aspect-ratio: 16/13;
`;

export default function ImageGrid() {
	// Change the Game Source to display different Image
	const data = [
		{ number: "1.", title: "Build your Path Infrastructure", imageSource: GameImage, direction: "row" },
		{
			number: "2.",
			title: "Build your Plant Army to defend your Gardens",
			imageSource: GameImage,
			direction: "row-reverse",
		},
		{
			number: "3.",
			title: "Trade your Plants  with Players all around the World",
			imageSource: GameImage,
			direction: "row",
		},
	];
	<TextWrapper></TextWrapper>;
	return (
		<Container>
			<LeftSideWave src={LeftWave} alt='Left Wave vector' loading='lazy' />
			<RightSideWave src={RightWave} alt='Right Wave vector' />
			<Wrapper>
				<GridContainer>
					{data.map((item, id) => (
						<GridBox key={id}>
							<Grid
								container
								alignItems='center'
								justifyContent='space-evenly'
								direction={item.direction}
							>
								{/* Text Grid */}
								<Grid item xs={12} sm={6} md={5}>
									<TextWrapper>
										<Header>
											<GradientText>{item.number}</GradientText> &nbsp;
											{item.title}
										</Header>
									</TextWrapper>
								</Grid>
								{/* Image Grid */}
								<Grid item xs={12} sm={6} md={5}>
									<ImageWrapper>
										<Image src={item.imageSource} alt={item.title} />
									</ImageWrapper>
								</Grid>
							</Grid>
						</GridBox>
					))}
				</GridContainer>
			</Wrapper>
		</Container>
	);
}
