import React from "react";
import styled from "styled-components";

const Link = styled.a``;

const Button = styled.button`
	color: #ffa500;
	font: 0.9rem Montserrat;
	font-weight: 600;
	background-color: transparent;
	cursor: pointer;
	border: 2px solid #ffa500;
	border-radius: 1rem 0 1rem 0;
	letter-spacing: 0.5px;
	margin-left: 1rem;
	padding: 0.7rem 1rem;

	&:hover {
		background-color: var(--ButtonHoverColor);
		color: var(--LightTextColor);
		transition: all 0.2s linear;
	}

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.65rem;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem 0 0.5rem 0;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
		padding: 1.2rem 2.5rem;
		margin-left: 2rem;
	}
`;

export default function SecondaryButton({ href, children }) {
	return (
		<Link href={href}>
			<Button>{children}</Button>
		</Link>
	);
}
