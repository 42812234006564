import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";

// images
import NavBarWaveImage from "../../Assets/Images/NavWave.png";
import LeftWave from "../../Assets/Images/GridLeftWave.png";
import GameImage from "../../Assets/Images/Gameplay/MobileProp.png";

// Shared Component
import OrangeButton from "../../components/OrangeButton.js";
import SecondaryButton from "../../components/SecondaryButton.js";
import GradientText from "../../components/GradientText.js";

// styled component styles
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 8rem 0 10rem 0;

	@media (max-width: 600px) {
		padding: 8rem 0 1rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 8rem 0 4rem 0;
	}
`;

const NavBarWave = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	width: 70%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

// const BottomBarWave = styled.img`
// 	position: absolute;
// 	bottom: -8%;
// 	left: 0;
// 	z-index: -1;
// 	width: 60%;
// 	object-fit: contain;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;

// 	@media (max-width: 600px) {
// 		display: none;
// 	}
// 	@media (min-width: 601px) and (max-width: 1024px) {
// 		width: 60%;
// 		bottom: -15%;
// 	}
// `;

const LeftSideWave = styled.img`
	position: absolute;
	top: 5%;
	left: -2%;
	z-index: -1;
	height: 130%;
	object-fit: contain;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 600px) {
		display: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		top: -5%;
		height: 150%;
	}
	@media (min-width: 1900px) {
		height: 200%;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextContainer = styled.div`
	@media (max-width: 600px) {
		text-align: center;
		margin-bottom: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const Header = styled.h1`
	color: var(--DarkGreen);
	font: 3.5rem Montserrat;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
	@media (min-width: 1900px) {
		font-size: 6rem;
	}
`;

const DescriptionWrapper = styled.div`
	margin-top: 2rem;
	width: 90%;

	@media (max-width: 600px) {
		width: 85%;
		margin: 2rem auto 0 auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 1rem;
	}
`;

const Description = styled.p`
	color: var(--GreyDescriptionColor);
	font: 1rem Montserrat;
	letter-spacing: 0.5px;
	line-height: 170%;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 1.8rem;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 4rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 2rem;
	}
`;

const ImageContainer = styled.div``;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
	aspect-ratio: 16/13;
`;

export default function Hero() {
	return (
		<Container id='homeSection'>
			<NavBarWave src={NavBarWaveImage} alt='Left Wave vector' loading='lazy' />
			<LeftSideWave src={LeftWave} alt='Right Wave vector' loading='lazy' />
			<Wrapper>
				<Grid container alignItems='center'>
					{/* Image Text */}
					<Grid item xs={12} sm={6} md={6}>
						<TextContainer>
							<Header>
								Get the <GradientText>REAL Royale</GradientText> fun game addiction, Play now for free!
							</Header>
							<DescriptionWrapper>
								<Description>
									Get started today with Farm Royale on Android devices. Let us know what you think
									about this awesome new app by leaving a review/suggestion/bug down below.
								</Description>
							</DescriptionWrapper>
							<ButtonWrapper>
								{/* Edit the href (link) if you want to update the link */}
								<OrangeButton href='https://docs.google.com/forms/d/e/1FAIpQLSfbdlXdcdyM-H2Q5MyjDO9PrhwQzuRa6lMKZXOeRXIg6O9qIw/viewform'>
									Play Now
								</OrangeButton>
								<SecondaryButton href='/'>Explore More</SecondaryButton>
							</ButtonWrapper>
						</TextContainer>
					</Grid>
					{/* Image Grid */}
					<Grid item xs={12} sm={6} md={6}>
						<ImageContainer>
							<Image src={GameImage} alt='Farm Royale Hero Image' loading='lazy' />
						</ImageContainer>
					</Grid>
				</Grid>
			</Wrapper>
		</Container>
	);
}
