import "./App.css";

// component
import NavigationBar from "./components/NavBar.js";
import Footer from "./components/Footer.js";

// routes
import Index from "./routes/Home/Index.js";

function App() {
	return (
		<div>
			<NavigationBar />
			<Index />
			<Footer />
		</div>
	);
}

export default App;
