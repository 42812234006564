import React from "react";
import styled from "styled-components";

// styled component styles
const Text = styled.span`
	background: -webkit-linear-gradient(to right, #fd1c03, #ffa500);
	background: -o-linear-gradient(to right, #fd1c03, #ffa500);
	background: -moz-linear-gradient(to right, #fd1c03, #ffa500);
	background: linear-gradient(to right, #fd1c03, #ffa500);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	font-weight: 900;
`;

export default function GradientText({ children }) {
	return <Text>{children}</Text>;
}
